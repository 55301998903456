import {ButtonConnect} from "../../components/ui/button";
import React,{ useState } from "react";

const LetConnectUS = (props) => {

  const [active,setActive] =useState(0)

    return (         
      <div className="container">
        <div className="row">
          <div className="col-md-12">

          <div className="title">Our Locations</div>

            <div className="map-points" style={{display:'flex'}}>

              <span className={`point ${ active === 0 ? "active" : "" }`} onMouseLeave={()=>{setActive(0)}} >
              
                <div className="address-col" >
                  
                  <b className="name">United Kingdom</b>
                  <span className="head-office" >Freston Analytics UK Ltd</span>
                  <span className="address">
                        Parkside, London road, Ipswich, IP2 0SS
                    {/* 200 Brook Drive. Green Park, Reading. RG2 6UB  */}
                    <br/> United Kingdom.
                  </span>
                </div>

              </span>

              <span className="point" onMouseOver={()=>{setActive(1)}} onMouseLeave={()=>{setActive(0)}}> 

                <div className="address-col">
                  <b className="name">UAE</b>
                  <span className="head-office">Mobisense Information Technology Network Services Co. LLC
  
                  </span>
                  <span className="address">
                  
                    #43/44, Building of Dubai Muncipality,
                    Bur Dubai-Al Fahidi,
                    Dubai,
                    <br/> 
                    United Arab Emirates
                    <br/> 
                  </span>
                </div>
                
              </span>

              {/* <span className="point" onMouseOver={()=>{setActive(2)}} onMouseLeave={()=>{setActive(0)}}>
                <div className="address-col">
                  <b className="name">Qatar</b>
                  <span className="head-office">Cloudwise technologies Ltd</span>
                  <span className="address">
      
                    <br/>
                    Doha , Qatar
                  </span>
                </div>
              </span> */}

              <span className="point" onMouseOver={()=>{setActive(3)}} onMouseLeave={()=>{setActive(0)}}>
                <div className="address-col">
                  <b className="name">India</b>

                  <span className="head-office">Freston Analytics Pvt Ltd</span>

                  <span className="address">
                      #65/1515, Thanveer Complex.
                      West Nadakkavu, Kozhikode.
                      Kerala, India 673011.
                  </span>

                  <span className="head-office" >Development Centers</span>

                  <span className="address">
                      #03,GF, Sahya,
                      Govt. Cyberpark.
                      Kozhikode.
                      Kerala, India 673016.
                  </span>
                  
                  <span className="address">
                      #27,B1, Sahya,
                      Govt. Cyberpark.
                      Kozhikode.
                      Kerala, India 673016.
                  </span>

               
                </div>
              </span>
              
            </div>

            {
              props?.page !== 'let-connect' && (
                <div className="letconnect-container" >
                  <ButtonConnect to='/contact-us' label="LET'S CONNECT"/>
                </div> 
              )
            }

          </div>
        </div>
      </div>
  );

}

export default LetConnectUS;